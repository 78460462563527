import { useSelector } from 'react-redux'

import Badge from 'react-bootstrap/Badge'
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import {
    selectHistoryWorkouts,
} from './historySlice'

import {
    ExcerciseNames,
} from '../excercises/currentWorkoutSlice'

const colorScheme = {
};

const ExcerciseChart = ({excerciseKey, workouts}) => {
    const dataPoints = [];
    const weightPoints = [];
    for (let workoutKey in workouts) {
        const workout = workouts[workoutKey];
        const excercise = workout.excercises.filter((item) => item.id === excerciseKey)[0];
        if (excercise === undefined) {
            continue;
        }

        var start = undefined;
        var finish = undefined;
        var weight = 0;
        var maxWeight = 0;
        var repsTotal = 0;
        for (let rep of excercise.reps) {
            if (rep.toggledAt !== undefined) {

                if (rep.type === 'warmup' && (start === undefined || start < rep.toggledAt)) {
                    start = rep.toggledAt;
                }

                if (rep.type !== 'warmup' && (start === undefined || start > rep.toggledAt)) {
                    start = rep.toggledAt;
                }

                if (finish === undefined || finish < rep.toggledAt) {
                    finish = rep.toggledAt;
                }
            }

            if (rep.finishTimestamp !== undefined) {
                if (rep.type === 'warmup' && (start === undefined || start < rep.finishTimestamp)) {
                    start = rep.startTimestamp;
                }
                if (finish === undefined || finish < rep.finishTimestamp) {
                    finish = rep.finishTimestamp;
                }
            }

            if (rep.startTimestamp !== undefined) {
                if (rep.type !== 'warmup' && (start === undefined || start > rep.startTimestamp)) {
                    start = rep.startTimestamp;
                }
            }

            if (rep.type === 'warmup') {
                continue;
            }
            if (rep.done > 0 && rep.weight > maxWeight) {
                maxWeight = rep.weight;
            }

            repsTotal += rep.done;
            weight += rep.weight * rep.done;
        }
        if (start === undefined || finish === undefined) {
            continue;
        }
        const delta = (finish - start) / 1000;
        if (delta === 0) {
            continue;
        }
        const kgPerSecond = weight / delta;
        if (kgPerSecond > 100) {
            continue;
        }
        const commonRec = {
            x: new Date(start),
            perf: kgPerSecond,
            weight: maxWeight,
            totalWeight: weight,
            reps: repsTotal,
        }
        dataPoints.push({y: kgPerSecond, ...commonRec});
        weightPoints.push({y: weight, ...commonRec});
    }

    const options = {
        credits: {
            enabled: false,
        },
        title: {
            style: {
                color: 'rgb(222, 226, 230)',
            },
            text: ExcerciseNames[excerciseKey],
        },
        xAxis: {
            labels: {
                style: {
                    color: 'rgb(222, 226, 230)',
                },
            },
            type: 'datetime',
        },
        yAxis: [
            {
                title: {
                    text: 'kg/sec',
                    style: {
                        color: 'rgb(222, 226, 230)',
                    },
                },
                labels: {
                    format: '{value:.2f}',
                    style: {
                        color: 'rgb(222, 226, 230)',
                    },
                },
            },
            {
                title: {
                    text: 'kg',
                    style: {
                        color: 'rgb(222, 226, 230)',
                    },
                },
                labels: {
                    format: '{value}',
                    style: {
                        color: 'rgb(222, 226, 230)',
                    },
                },
                opposite: true,
            },
        ],
        legend: {
            enabled: true,
            itemStyle: {
                color: 'rgb(222, 226, 230)',
            },
        },
        chart: {
            // type: 'scatter',
            backgroundColor: 'rgba(255, 255, 255, 0)',
        },
        series: [
            {
                name: 'Performance',
                data: dataPoints,
                type: 'scatter',
            },
            {
                name: 'Weight',
                data: weightPoints,
                yAxis: 1,
                type: 'line',
            },
        ],
        tooltip: {
            shared: true,
            crosshairs: true,
            formatter: function() {
                return `${this.x}<br /><b>Performance:</b> ${this.point.perf.toFixed(2)} kg/s<br /><b>Weight:</b> ${this.point.weight.toFixed(2)} kg<br /><b>Total weight:</b> ${this.point.totalWeight.toFixed(2)} kg<br /><b>Reps:</b> ${this.point.reps}`
            }
        }
    };

            //<h2>{ExcerciseNames[excerciseKey]}</h2>
    return (
        <>
            <div style={{marginBottom: '2em'}}>
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
              />
            </div>
        </>
    );
}

const Analytics = () => {
    const workouts = useSelector(selectHistoryWorkouts);
    const excercises = {};

    for (let workout of Object.values(workouts)) {
        for (let excercise of workout.excercises) {
            excercises[excercise.id] = excercise;
        }
    }
    const excercisesKeys = Object.keys(excercises).toSorted();
    const charts = [];
    for (let excercise of excercisesKeys) {
        charts.push(
            <ExcerciseChart key={excercise} excerciseKey={excercise} workouts={workouts} />
        );
    }
    return (<>{charts}</>);
}

export default Analytics
