import axios from 'axios'
import Cookies from 'js-cookie'


class KvStorage {

    constructor(url) {
        this.url = url;
        this.urlPrefix = undefined;
        this.origin = this.url
        this.authPage = `${this.url}/auth/storage-key/ydx`;
        let key = Cookies.get('kvstorage');
        if (key !== undefined && key !== null && key !== '') {
            this.setKey(key);
        }
    }


    setKey(key) {
        this.urlPrefix = `${this.url}/store/${key}`;
        // console.log('Got url prefix', this.urlPrefix, key);
        Cookies.set('kvstorage', key, {expires: 365 * 10});
    }

    resetKey() {
        Cookies.remove('kvstorage');
    }

    isInitialized() {
        return this.urlPrefix !== undefined;
    }

    getItem(key) {
        if (!this.isInitialized()) {
            return new Promise(() => {return undefined});
        }
        return axios.get(
            `${this.urlPrefix}/${key}`,
            {
                responseType: 'text',
                crossDomain: true,
            },
        ).then(
            (result) => {
                return result.data;
            }
        )
    }

    setItem(key, value) {
        if (!this.isInitialized()) {
            return new Promise(() => {return undefined});
        }
        return axios.put(
            `${this.urlPrefix}/${key}`,
            value,
            {
                crossDomain: true,
            },
        )
    }

    removeItem(key) {
        if (!this.isInitialized()) {
            return new Promise(() => {return undefined});
        }
        return axios.delete(
            `${this.urlPrefix}/${key}`,
            '',
            {
                crossDomain: true,
            },
        )
    }
};

export const KvStorageSingleton = new KvStorage('https://kvstorage.net');

export default KvStorage;
