import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import {
  repToggled,
  selectExcercise,
  selectStats,
  weightChanged,
} from './currentWorkoutSlice'


const Timer = ({start, finish}) => {
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');

  const getTime = (start, finish) => {
    if (start === undefined) {
        start = Date.now();
    }
    if (finish === undefined) {
        finish = Date.now();
    }
    const time = finish >= start ? finish - start: 0;
    // console.log('TIME', time, Date.now(), start);
    setMinutes(String(Math.floor(time / 1000 / 60)).padStart(2, '0'));
    setSeconds(String(Math.floor((time / 1000) % 60)).padStart(2, '0'));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(start, finish), 1000);
    return () => clearInterval(interval);
  }, [start, finish]);

  if (finish === undefined && start !== undefined) {
      return (
        <>
            <strong style={{color: "#ffffff"}}>{minutes}:{seconds}</strong>
        </>
      );
  }

  if (start === undefined && finish === undefined) {
      return (
        <>
            <span>00:00</span>
        </>
      );
  }

  return (
    <>
        <span>{minutes}:{seconds}</span>
    </>
  );
};


const max = (x, y) => {return x > y ? x : y};

const Excercise = ({id}) => {
    const excercise = useSelector(state => selectExcercise(state, id));
    const dispatch = useDispatch();
    const stats = useSelector(selectStats);
    let info = undefined;
    /*
    if (id in stats.bestExcercise) {
        const ex = stats.bestExcercise[id];
        const count = ex.count > 1 ? <Badge>x{ex.count}</Badge> : null;
        info = (
            <>
                <Badge>{ex.weight} kg</Badge> {count}
            </>
        );
    }
    */

    if (stats.history !== undefined && id in stats.history && stats.history[id].length > 0) {
        const badges = [];
        const history = stats.history[id];
        const minIdx = history.length > 3 ? history.length - 3 : 0;
        for (let idx = minIdx; idx < history.length; idx++) {
            const ex = history[idx];
            badges.push(
                <Badge
                    bg={ex.success ? "success" : "danger"}
                    style={{marginLeft:"0.5em"}}
                    className="label"
                    key={idx}
                >
                    {ex.weight}kg
                </Badge>
            );
        }
        info = (<>{badges}</>);
    }

    const incWeight = (e) => {
        e.preventDefault();
        dispatch(weightChanged({
            excerciseId: id,
            weight: excercise.targetWeight + 2.5,
        }));
    }

    const decWeight = (e) => {
        e.preventDefault();
        dispatch(weightChanged({
            excerciseId: id,
            weight: max(excercise.targetWeight - 2.5, 20),
        }));
    }

    const reps = [];
    let prevRep = undefined;
    for (let rep of excercise.reps) {
        const buttonColor = rep.type === 'main' ? 'primary' : 'success';
        const variant = rep.target === rep.done ? buttonColor : `outline-${buttonColor}`;
        const repsValue = (rep.done === 0 || rep.done === rep.target) ? rep.target : `${rep.done} / ${rep.target}`;
        const plates = rep.plates !== undefined ? rep.plates.join(' ') : undefined;

        const excerciseTimer = (
            <Timer
                key={"rest-timer-" + rep.id}
                start={prevRep ? prevRep.toggledAt: undefined}
                finish={rep.toggledAt}
            />
        );

        prevRep = rep;

        reps.push(
          <Row key={rep.id} className="mb-2">
            <Col md={12} xs={12}>
                <div className="d-grid">
                    <Button
                        variant={variant}
                        onClick={
                            (e) => dispatch(repToggled({
                                excerciseId: id,
                                repId: rep.id,
                                toggledAt: Date.now(),
                            }))
                        }
                    >
                    {rep.weight}kg {repsValue}x
                    <br />
                    {plates}&nbsp;
                    <br />
                    {excerciseTimer}
                  </Button>
                </div>
            </Col>
          </Row>
        );
    }

    return (
        <>
            <Row className="justify-content-md-center">
                <Col md={5} xs={5}>
                    <h3>{excercise.name}</h3>
                </Col>
                <Col md={6} xs={6}>
                    {info}
                </Col>
            </Row>
            <Row className="mb-2">
                <Col md={1} xs={1}>
                    <Button onClick={decWeight}>-</Button>
                </Col>
                <Col md={2} xs={4} className="d-flex justify-content-center">
                    {excercise.targetWeight} kg
                </Col>
                <Col md={1} xs={1}>
                    <Button onClick={incWeight}>+</Button>
                </Col>
            </Row>
            {reps}
        </>
    );
}

export default Excercise
